body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  color: #000;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
